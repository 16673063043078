
import { defineComponent } from 'vue'
import { parse as parseQuery } from 'query-string'

export default defineComponent({
  mounted() {
    const query = parseQuery(window.location.search)

    const user = localStorage.getItem('user') || null

    if (!user) {
      const isTokenExist = window.location?.search?.includes('?token=')

      this.$router.push({
        path: '/loading',
        query: {
          toSignUp: window.location.pathname === '/signup' ? 'true' : 'false',
          toChangePassword: window.location.pathname === '/changePassword' ? 'true' : 'false',
          token: isTokenExist ? window.location.search.replace('?token=', '') : null,
          moonclerkCustomerId: query.moonclerk_customer_id,
        },
      })
    }
  },
})
