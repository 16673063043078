import { defineComponent } from 'vue'
import gql from 'graphql-tag'
import type { login, login_login_user } from 'schema-types/login'
import TwoFactorEnable from '@/components/TwoFactorEnable/TwoFactorEnable.vue'
import { currentUserQuery } from '@/queries'

export default defineComponent({
  name: 'Login',

  data() {
    return {
      required: (val?: string) => (val && val.length > 0) || 'This field is required',
      enableTwoFactorDialog: false,
      emailInput: localStorage.getItem('userEmail') || '',
      passwordInput: '',
      totpInput: '',
      loggingIn: false,
      user: {} as login_login_user,
      appDisplayName: process.env.VUE_APP_DISPLAYNAME,
      logoPath: require(`@/assets/logo-${process.env.VUE_APP_NAME}.png`),
    }
  },

  methods: {
    login() {
      this.loggingIn = true
      this.$apollo
        .mutate<login>({
          mutation: gql`
            mutation login($email: String!, $password: String!, $twoFactorToken: String) {
              login(email: $email, password: $password, twoFactorToken: $twoFactorToken) {
                token
                refreshToken
                user {
                  id
                  name
                  authLevel
                  twoFactorEnabled
                  twoFactorRequired
                }
              }
            }
          `,
          variables: {
            email: this.emailInput,
            password: this.passwordInput,
            twoFactorToken: this.totpInput,
          },
          update: (cache, { data }) => {
            if (data) {
              const { user, token } = data.login

              cache.writeQuery({
                query: currentUserQuery,
                data: { currentUser: { token, ...user } },
              })
            }
          },
        })
        .then(({ data }: any) => {
          if (data) {
            const user = data.login.user

            this.user = user
            this.loggingIn = false

            localStorage.setItem('userEmail', this.emailInput)
            localStorage.setItem(
              'user',
              JSON.stringify({
                ...data.login.user,
                token: data.login.token,
                refreshToken: data.login.refreshToken,
              }),
            )

            if (user.twoFactorEnabled || !user.twoFactorRequired) {
              this.finishLogin()
            } else {
              // Client must enable 2FA to continue
              this.$q.notify(
                'Your administrator requires that you enable 2 factor authentication on your account.' +
                  ' Please complete the form to continue.',
              )
              this.enableTwoFactorDialog = true
            }
          }
        })
        .catch((err: Error) => {
          this.loggingIn = false
          this.$q.dialog({
            title: 'Error',
            message: err.message.replace('GraphQL error:', '').trim(),
          })
        })
    },
    finishLogin() {
      this.$q.notify(`Logged in as ${this.user.name}.`)
      this.$router.push('/clients')
    },
  },
  components: {
    TwoFactorEnable,
  },
})
