import gql from 'graphql-tag'
import { AllUserData } from '@/fragments'

export const usersQuery = gql`
  query users(
    $page: Int!
    $perPage: Int!
    $search: String
    $onlySelfRemoval: Boolean
    $all: Boolean
  ) {
    users(
      page: $page
      perPage: $perPage
      search: $search
      onlySelfRemoval: $onlySelfRemoval
      all: $all
    ) {
      data {
        ...AllUserData
      }
      totalPages
      totalCount
    }
  }
  ${AllUserData}
`
