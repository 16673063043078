interface ILocalFilter {
  item:
    | 'clients-filter'
    | 'users-filter'
    | 'sites-filter'
    | 'dsh-filter'
    | 'reports-filter'
    | 'instruction-filter'
    | 'referrers-filter'
}

class LocalStoreFilters {
  setItem(item: ILocalFilter['item'], value: any) {
    localStorage.setItem(item, JSON.stringify(value))
  }

  getItem(item: ILocalFilter['item']) {
    const value = localStorage.getItem(item)

    return value ? JSON.parse(value) : null
  }

  deleteItem(item: ILocalFilter['item']) {
    localStorage.removeItem(item)
  }
}

export const localStoreFilters = new LocalStoreFilters()
