import { formatForDisplay, localStoreFilters, sortWithDate } from '@/utils'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Referrers',

  data() {
    const filters = localStoreFilters.getItem('referrers-filter') as
      | {
          sortBy: string
          descending: boolean
          rowsPerPage: number
        }
      | undefined

    return {
      queryError: false,
      referrers: [],
      table: {
        columns: [
          {
            name: 'createdAt',
            required: true,
            label: 'Created At',
            align: 'left',
            field: 'createdAt',
            sortable: true,
          },
          {
            name: 'referrer',
            required: true,
            label: 'Referrer',
            align: 'left',
            field: 'referrer',
            sortable: true,
          },
          {
            name: 'email',
            align: 'left',
            label: 'User Email',
            field: 'email',
            sortable: true,
          },
        ],
        pagination: {
          sortBy: filters?.sortBy || 'createdAt',
          descending: filters?.descending || false,
          page: 1,
          rowsPerPage: filters?.rowsPerPage !== undefined ? filters.rowsPerPage : 50,
        },
      },
    }
  },

  apollo: {
    referrers: {
      query: gql`
        query signupReferrers {
          signupReferrers {
            id
            createdAt
            referrer
            user {
              id
              email
            }
          }
        }
      `,
      error(err) {
        ;(this as any).queryError = err
      },
      result({ data }) {
        if (data) {
          ;(this as any).queryError = false
        }
      },
      update(data) {
        return data.signupReferrers.map((referrer: any) => ({
          ...referrer,
          email: referrer.user.email,
        }))
      },
    },
  },

  watch: {
    'table.pagination': {
      handler() {
        this.saveFilters()
      },
    },
  },

  methods: {
    saveFilters() {
      localStoreFilters.setItem('referrers-filter', {
        sortBy: this.table.pagination.sortBy,
        descending: this.table.pagination.descending,
        rowsPerPage: this.table.pagination.rowsPerPage,
      })
    },

    formatForDisplay,

    sortWithDate,

    filterColumnData(columnData: { [index: string]: string }) {
      const columnOrder = this.table.columns.map(({ field }) => field)
      const exclude = ['__index', 'id', '__typename', 'user']

      return Object.keys(columnData)
        .filter((key) => !exclude.includes(key))
        .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
        .reduce((obj: { [index: string]: string }, key) => {
          obj[key] = columnData[key]
          return obj
        }, {})
    },
  },
})
