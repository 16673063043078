import date from 'quasar/src/utils/date.js';
const { formatDate } = date

export const formatForDisplay = (
  data: boolean | string | null | number,
  name: string,
): string | null => {
  if (typeof data === 'boolean') {
    return data ? 'yes' : 'no'
  }
  if (
    [
      'createdAt',
      'updatedAt',
      'siteStatusUpdatedAt',
      'nextScan',
      'scannedAt',
      'movedToMonitoringAt',
    ].includes(name)
  ) {
    const parsedDate = typeof data === 'string' ? Date.parse(data as string) : data
    if (isNaN(parsedDate as any)) {
      // For dates like 'Never'
      return data as string
    }
    return formatDate(
      parsedDate,
      `MMMM Do, YYYY${
        ['nextScan', 'scannedAt', 'movedToMonitoringAt'].includes(name) ? '' : ', h:mm a'
      }`,
    )
  }
  return data as string
}
