<template>
  <div class="fixed-center col items-center">
    <div class="row justify-center">
      <img width="200" height="200" :src="logoPath" alt="logo" />
    </div>
    <div class="pt-4 pb-2 row justify-center">
      <q-spinner size="32px" />
    </div>
    <div class="row justify-center">
      <p class="text-h6">Loading App...</p>
    </div>
  </div>
</template>

<script src="./Loading.ts"></script>

<style></style>
