import { defineComponent } from 'vue'
import gql from 'graphql-tag'
import type { createInstructionDocument } from 'schema-types/createInstructionDocument'
import type { updateInstructionDocument } from 'schema-types/updateInstructionDocument'
import type { ApolloError } from '@apollo/client'
import { instructionDocumentsQuery } from '@/queries'
import { localStoreFilters } from '@/utils'

export default defineComponent({
  name: 'InstructionDocuments',

  data() {
    const filters = localStoreFilters.getItem('instruction-filter') as
      | {
          sortBy: string
          descending: boolean
          rowsPerPage: number
        }
      | undefined

    return {
      queryError: false as boolean | ApolloError,
      editDialog: false,
      instructionDocuments: [],
      table: {
        columns: [
          {
            name: 'edit',
            required: true,
            label: 'Edit',
            align: 'left',
            sortable: false,
          },
          {
            name: 'name',
            required: true,
            label: 'Name',
            align: 'left',
            field: 'name',
            sortable: true,
          },
          {
            name: 'url',
            align: 'left',
            label: 'Url',
            field: 'url',
            sortable: true,
          },
        ],
        pagination: {
          sortBy: filters?.sortBy || 'name',
          descending: filters?.descending || false,
          page: 1,
          rowsPerPage: filters?.rowsPerPage !== undefined ? filters.rowsPerPage : 50,
        },
      },
      instructionDocumentData: {
        name: '',
        url: '',
        id: '',
      },
      saving: false,
    }
  },

  apollo: {
    instructionDocuments: {
      query: instructionDocumentsQuery,
      error(err) {
        this.queryError = err
      },
      result({ data }) {
        if (data) {
          this.queryError = false
        }
      },
    },
  },

  watch: {
    'table.pagination': {
      handler() {
        this.saveFilters()
      },
    },
  },

  methods: {
    saveFilters() {
      localStoreFilters.setItem('instruction-filter', {
        sortBy: this.table.pagination.sortBy,
        descending: this.table.pagination.descending,
        rowsPerPage: this.table.pagination.rowsPerPage,
      })
    },

    filterColumnData(columnData: { [index: string]: string }) {
      const exclude = ['__index', 'id', '__typename']
      return Object.keys(columnData)
        .filter((key) => !exclude.includes(key))
        .reduce((obj: { [index: string]: string }, key) => {
          obj[key] = columnData[key]
          return obj
        }, {})
    },

    saveInstructionDocument() {
      this.saving = true
      if (this.instructionDocumentData.id) {
        // Update instruction document
        this.$apollo
          .mutate<updateInstructionDocument>({
            mutation: gql`
              mutation updateInstructionDocument($id: String!, $name: String, $url: String) {
                updateInstructionDocument(id: $id, name: $name, url: $url) {
                  id
                  name
                  url
                }
              }
            `,
            variables: { ...this.instructionDocumentData },
          })
          .then(() => {
            this.saving = false
          })
          .catch((err) => {
            this.saving = false
            this.$q.dialog({
              title: 'Error',
              message: err.message.replace('GraphQL error:', '').trim(),
            })
          })
      } else {
        // Create instruction document
        this.$apollo
          .mutate<createInstructionDocument>({
            mutation: gql`
              mutation createInstructionDocument($name: String!, $url: String!) {
                createInstructionDocument(name: $name, url: $url) {
                  id
                  name
                  url
                }
              }
            `,
            variables: this.instructionDocumentData,
            refetchQueries: ['instructionDocuments'],
          })
          .then(() => {
            this.saving = false
            this.instructionDocumentData = {
              id: '',
              name: '',
              url: '',
            }
          })
          .catch((err) => {
            this.saving = false
            this.$q.dialog({
              title: 'Error',
              message: err.message.replace('GraphQL error:', '').trim(),
            })
          })
      }
    },
  },
})
