export const sortWithDate = (rows: any[], sortBy: string, descending: boolean): any[] => {
    const data = [...rows]

    if (sortBy) {
        data.sort((a, b) => {
            const x = descending ? b : a
            const y = descending ? a : b
            if (
                ['createdAt', 'updatedAt', 'nextScan'].includes(sortBy) &&
                typeof x[sortBy] === 'string'
            ) {
                // Date sort
                if (y[sortBy] === 'Never') {
                    return -1
                }
                if (x[sortBy] === 'Never') {
                    return 1
                }
                return Date.parse(y[sortBy] as string) - Date.parse(x[sortBy] as string)
            } else if (typeof x[sortBy] === 'number') {
                // Numeric sort
                return x[sortBy] - y[sortBy]
            } else {
                // String sort
                return x[sortBy] > y[sortBy] ? 1 : x[sortBy] < y[sortBy] ? -1 : 0
            }
        })
    }

    return data
}
