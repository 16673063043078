import gql from 'graphql-tag'

export const AllUserData = gql`
  fragment AllUserData on User {
    id
    name
    email
    authLevel
    twoFactorEnabled
    twoFactorRequired
    accountLocked
    deepScanPermissions
  }
`
