import gql from 'graphql-tag'

export const siteCategoriesQuery = gql`
  query siteCategories {
    siteCategories {
      id
      name
    }
  }
`
