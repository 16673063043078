<template>
  <q-select
    filled
    use-input
    :modelValue="modelValue"
    @update:modelValue="(value) => $emit('update:modelValue', value)"
    input-debounce="250"
    label="Assignment"
    :options="assignmentUsers"
    @filter="searchUsers"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">No results</q-item-section>
      </q-item>
    </template>
    <template v-slot:after>
      <q-btn @click="$emit('update:modelValue', { value: '', label: '' })" icon="clear" flat round>
        <q-tooltip>Clear Assignments</q-tooltip>
      </q-btn>
    </template>
  </q-select>
</template>

<script src="./AssignmentSelector.ts"></script>
