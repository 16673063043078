<template>
  <q-layout view="hHh lpR lfr" class="overflow-hidden">
    <q-header class="bg-white shadow" v-if="!isClientMoreInfo">
      <q-toolbar v-if="!['Login', 'SignUp', 'Loading'].includes($route.name)">
        <q-btn
          v-if="currentUser"
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="menu"
          color="primary"
        />

        <q-toolbar-title class="text-primary" @click="$router.go(-1)">{{
          toolbarTitle
        }}</q-toolbar-title>
      </q-toolbar>
    </q-header>

    <q-drawer v-if="currentUser && !isClientMoreInfo" v-model="leftDrawerOpen" content-class="bg-white">
      <div class="column no-wrap justify-between full-height p-2">
        <q-list class="q-gutter-sm">
          <q-expansion-item
            v-model="expandInstructions"
            header-class="q-item__label--header"
            label="Instructions"
          >
            <a
              style="text-decoration: none; color: #000000"
              v-for="link in instructionDocuments"
              :key="link.id"
              :href="link.url"
              target="_blank"
            >
              <q-item clickable>
                <q-item-section>
                  <q-item-label>{{ link.name }}</q-item-label>
                </q-item-section>
              </q-item>
            </a>
          </q-expansion-item>

          <q-item-label header>Navigation</q-item-label>
          <q-item active-class="bg-blue-100 rounded text-primary" to="/clients" exact>
            <q-item-section avatar>
              <q-icon name="home" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ isUser ? 'Clients' : 'Home' }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-item active-class="bg-blue-100 rounded text-primary" v-if="isUser" to="/queue" exact>
            <q-item-section avatar>
              <q-icon name="switch_account" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Queue</q-item-label>
            </q-item-section>
          </q-item>
          <q-item
            active-class="bg-blue-100 rounded text-primary"
            v-if="isAdmin"
            to="/queuelog"
            exact
          >
            <q-item-section avatar>
              <q-icon name="timeline" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Queue Log</q-item-label>
            </q-item-section>
          </q-item>
          <q-item active-class="bg-blue-100 rounded text-primary" v-if="isAdmin" to="/users" exact>
            <q-item-section avatar>
              <q-icon name="supervised_user_circle" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Users</q-item-label>
            </q-item-section>
          </q-item>
          <q-item active-class="bg-blue-100 rounded text-primary" v-if="isAdmin" to="/sites" exact>
            <q-item-section avatar>
              <q-icon name="web" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Sites</q-item-label>
            </q-item-section>
          </q-item>

          <!-- <q-item
            active-class="bg-blue-100 rounded text-primary"
            v-if="isUser"
            to="/deep-scan-hits"
            exact
          >
            <q-item-section avatar>
              <q-icon name="search" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Deep Scan Hits</q-item-label>
            </q-item-section>
          </q-item> -->

          <q-item
            active-class="bg-blue-100 rounded text-primary"
            v-if="isAdmin"
            to="/reports"
            exact
          >
            <q-item-section avatar>
              <q-icon name="list_alt" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Reports</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            active-class="bg-blue-100 rounded text-primary"
            v-if="isAdmin"
            to="/instructionDocuments"
            exact
          >
            <q-item-section avatar>
              <q-icon name="help" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Instruction Documents</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            active-class="bg-blue-100 rounded text-primary"
            v-if="isOwner"
            to="/security"
            exact
          >
            <q-item-section avatar>
              <q-icon name="lock" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Security</q-item-label>
            </q-item-section>
          </q-item>

          <q-item
            active-class="bg-blue-100 rounded text-primary"
            v-if="isAdmin"
            to="/referrers"
            exact
          >
            <q-item-section avatar>
              <q-icon name="list_alt" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Referrers</q-item-label>
            </q-item-section>
          </q-item>

          <q-item-label header>Account</q-item-label>

          <q-item
            active-class="bg-blue-100 rounded text-primary"
            :to="`/user/${currentUser.id}`"
            exact
          >
            <q-item-section avatar>
              <q-icon name="account_circle" />
            </q-item-section>

            <q-item-section>
              <q-item-label>My Account</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable @click="reloadWindow">
            <q-item-section avatar>
              <q-icon name="exit_to_app" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Log Out</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>

        <a
          v-if="appName === 'officerprivacy'"
          class="q-mb-md q-mt-xl"
          style="text-decoration: none; color: #000000"
          href="https://www.officerprivacy.com/upgrade/"
          target="_blank"
        >
          <q-item clickable>
            <q-item-section avatar>
              <q-icon name="help" />
            </q-item-section>

            <q-item-section>
              <q-item-label>Want OfficerPrivacy to remove your information?</q-item-label>
            </q-item-section>
          </q-item>
        </a>
      </div>
    </q-drawer>

    <transition :name="transitionName" mode="out-in">
      <q-page-container
        :key="['Login', 'SignUp'].includes($route.name) ? 1 : 0"
        :class="`bg-gray-100 min-h-screen color-slash-${$route.name}`"
      >
        <router-view v-slot="{ Component }">
          <transition :name="transitionName" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </q-page-container>
    </transition>

    <q-footer
      class="bg-gray-200 text-gray-800 px-2"
      v-if="!['Loading', 'Login', 'SignUp'].includes($route.name) && !isClientMoreInfo"
    >
      Have a suggestion? Email us at
      <a class="text-blue-800 underline" href="mailto:feedback@officerprivacy.com"
        >feedback@officerprivacy.com</a
      >. We read and seriously consider every suggestion. Thank you.
    </q-footer>
  </q-layout>
</template>

<script src="./Default.ts"></script>

<style>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.5s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-to,
.slide-right-enter-from {
  opacity: 0;
  transform: translate(-2em, 0);
}
@media (min-width: 768px) {
  .color-slash-Login {
    background-image: -webkit-linear-gradient(340deg, #ffffff 50%, var(--q-secondary) 50%);
  }
}
@media (min-width: 1024px) {
  .color-slash-SignUp {
    background-image: -webkit-linear-gradient(340deg, #ffffff 40%, var(--q-secondary) 40%);
  }
}
</style>
