import { defineComponent } from 'vue'
import ClientList from '@/views/ClientList/ClientList.vue'
import CompletedScanList from '@/components/CompletedScanList/CompletedScanList.vue'

export default defineComponent({
  name: 'Monitoring',
  components: {
    ClientList,
    CompletedScanList,
  },
})
