import {
    User_authLevel,
    displayStatuses,
    formatForDisplay,
    hasAuthLevel,
    openSiteUrl,
    sortWithDate,
} from '@/utils'
import type { ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import sanitize from 'sanitize-html'
import type { RemovalStatus } from 'schema-types/globalTypes'
import type { history_history } from 'schema-types/history'
import type { siteAndClient } from 'schema-types/siteAndClient'
import type { siteNote_siteNote } from 'schema-types/siteNote'
import { defineComponent } from 'vue'

const historyQuery = gql`
  query history($clientId: String!, $siteId: String!) {
    history(clientId: $clientId, siteId: $siteId) {
      id
      createdAt
      from
      to
    }
  }
`
const siteNoteQuery = gql`
  query siteNote($clientId: String!, $siteId: String!) {
    siteNote(clientId: $clientId, siteId: $siteId) {
      id
      note
    }
  }
`

export default defineComponent({
    name: 'SiteStatusView',

    data() {
        return {
            showAllRows: false,
            showCount: 3,
            isUser: false,
            updateStatusDialog: false,
            siteAndClient: {} as siteAndClient,
            siteAndClientError: undefined as ApolloError | undefined,
            selectedStatus: 'EXIST',
            gotToNextSiteAfterMutate: false,
            columns: [
                {
                    name: 'createdAt',
                    required: true,
                    label: 'Date',
                    align: 'left',
                    field: 'createdAt',
                    sortable: true,
                },
                {
                    name: 'from',
                    align: 'left',
                    label: 'From',
                    field: 'from',
                    sortable: true,
                },
                {
                    name: 'to',
                    align: 'left',
                    label: 'To',
                    field: 'to',
                    sortable: true,
                },
            ],
            pagination: {
                sortBy: 'createdAt',
                descending: true,
                page: 1,
                rowsPerPage: 0,
            },
            error: false as boolean | ApolloError,
            history: [] as history_history[],
            siteNote: {} as siteNote_siteNote,
            statusLoading: false,
        }
    },

    mounted() {
        hasAuthLevel(this.$apollo, User_authLevel.USER).then((isUser) => {
            this.isUser = isUser
        })
    },

    emits: ['goToNextSite'],

    apollo: {
        siteAndClient: {
            query: gql`
        query siteAndClient($siteId: String!, $clientId: String!) {
          site(id: $siteId) {
            id
            name
            notes
            url
            directOptOutPath
            directSearchPath
            directEmailSearchPath
            directPhoneSearchPath
            directAddressSearchPath
            optOutPath
            searchPath
            optOutWithoutSearch
            automationName
            instructionsUrl
            instructions {
              id
              text
              link
            }
          }
          client(id: $clientId) {
            id
            firstName
            lastName
            Client_phoneNumbers {
              value
            }
            Client_emails {
              value
            }
            addresses {
              streetAddress
              zipCode
              city
              state
            }
          }
        }
      `,
            manual: true,
            result({ data }: { data: siteAndClient }) {
                this.siteAndClientError = undefined
                this.siteAndClient = data
            },
            skip() {
                return !this.$route.params.siteId
            },
            variables() {
                return {
                    siteId: this.$route.params.siteId,
                    clientId: this.$route.params.clientId,
                }
            },
            error(e) {
                this.siteAndClientError = e
            },
        },

        siteNote: {
            query() {
                return siteNoteQuery
            },
            skip() {
                return !this.$route.params.siteId
            },
            variables() {
                return {
                    siteId: this.$route.params.siteId,
                    clientId: this.$route.params.clientId,
                }
            },
        },

        history: {
            query: historyQuery,

            variables() {
                return {
                    clientId: this.$route.params.clientId,
                    siteId: this.$route.params.siteId,
                }
            },

            skip() {
                return !this.$route.params.siteId
            },

            error(err) {
                this.error = err
            },

            result({ data }) {
                if (data) {
                    this.error = false
                    const historyData = data.history
                    if (historyData.length > 0) {
                        const latestStatus = historyData[historyData.length - 1].to
                        if (latestStatus) {
                            this.selectedStatus = latestStatus
                        }
                    }
                }
            },
        },
    },

    computed: {
        sortedHistory() {
            return this.sortWithDate(this.history, this.pagination.sortBy, this.pagination.descending);
        },
        displayedRows() {
            return this.sortedHistory.slice(0, this.showCount);
        },
        showMoreButton() {
            return this.history.length > this.showCount;
        },
        statusOptions(): Array<{ value: string; label: string }> {
            const useStatuses = Object.keys(displayStatuses).filter(
                (status) => this.isUser || status !== 'ADDEDANDREMOVED',
            )

            return useStatuses.map((status) => {
                let isCurrent
                if (this.history.length > 0) {
                    if (this.history[this.history.length - 1].to === status) {
                        isCurrent = true
                    }
                }
                let label = (displayStatuses as { [index: string]: string })[status]
                if (label === 'Unknown') {
                    label = 'Pending'
                }
                return {
                    value: status,
                    label: label + (isCurrent ? ' (current)' : ''),
                }
            })
        },
    },

    methods: {
        handleDirectSearch(type: 'address' | 'phone' | 'email' | 'optOut') {
            const client = this.siteAndClient.client;
            let items;
            switch (type) {
                case 'address':
                    items = client.addresses;
                    break;
                case 'phone':
                    items = client.Client_phoneNumbers;
                    break;
                case 'email':
                    items = client.Client_emails;
                    break;
                case 'optOut':
                    items = client.addresses;
                    break;
            }
            if (items.length === 1) {
                this.openDirectSearch(type, 0);
            }
        },

        openDirectSearch(type: 'address' | 'phone' | 'email' | 'name' | 'optOut', index: number) {
            const client = this.siteAndClient.client;
            const site = this.siteAndClient.site;
            let path;
            let value;

            switch (type) {
                case 'address':
                    path = site.directAddressSearchPath;
                    value = client.addresses[index];
                    break;
                case 'phone':
                    path = site.directPhoneSearchPath;
                    value = client.Client_phoneNumbers[index].value;
                    break;
                case 'email':
                    path = site.directEmailSearchPath;
                    value = client.Client_emails[index].value;
                    break;
                case 'name':
                    path = site.directSearchPath;
                    value = `${client.firstName} ${client.lastName}`;
                    break;
                case 'optOut':
                    path = site.directOptOutPath;
                    value = client.addresses[index];
                    break;
            }

            if (path) {
                this.openSiteUrlWithProps(site.url, path, client, index);
            }
        },

        openSiteUrlWithProps: function (url: string, path: string, client: any, index: number): void {
            let finalUrl = path;
            const addresses = client.addresses;
            const address = addresses[index] || addresses[0];
            const phone = client.Client_phoneNumbers[index]?.value || client.Client_phoneNumbers[0]?.value;
            const email = client.Client_emails[index]?.value || client.Client_emails[0]?.value;

            finalUrl = finalUrl.replace(/\${streetAddress}/g, address.streetAddress || '')
                .replace(/\${city}/g, address.city || '')
                .replace(/\${state}/g, address.state || '')
                .replace(/\${zipCode}/g, address.zipCode || '')
                .replace(/\${phone}/g, phone || '')
                .replace(/\${email}/g, email || '')
                .replace(/\${firstName}/g, client.firstName || '')
                .replace(/\${lastName}/g, client.lastName || '');

            window.open(url + finalUrl, '_blank');
        },

        showMore() {
            this.showCount = Math.min(this.showCount + 30, this.sortedHistory.length);
        },

        formatForDisplay(data: any, name: string) {
            if (['to', 'from', 'status'].includes(name) && typeof data === 'string') {
                if (data.toLowerCase() === 'unknown') {
                    return 'Pending'
                }
                return displayStatuses[data as RemovalStatus] || data
            }
            return formatForDisplay(data, name)
        },

        sortWithDate,

        async setStatusMutation() {
            this.statusLoading = true

            this.$apollo
                .mutate({
                    mutation: gql`
            mutation setStatusSite($status: RemovalStatus!, $clientId: String!, $siteId: String!) {
              setStatus(clientId: $clientId, status: $status, siteId: $siteId) {
                id
                from
                to
                createdAt
                siteId
              }
            }
          `,
                    variables: {
                        siteId: this.$route.params.siteId,
                        clientId: this.$route.params.clientId,
                        status: this.selectedStatus,
                    },
                })
                .then(() => {
                    this.$apollo.queries.history.refetch()
                    this.updateStatusDialog = false

                    if (this.gotToNextSiteAfterMutate) {
                        this.gotToNextSiteAfterMutate = false
                        this.$emit('goToNextSite')
                    }

                    // Only show the dialog for self removers
                    if (!this.isUser) {
                        this.$q.dialog({
                            title: 'Status',
                            message: 'Status changed.',
                        })
                    }
                })
                .catch((err) => {
                    this.$q.dialog({
                        title: 'Error status',
                        message: err.message.replace('GraphQL error:', '').trim(),
                    })
                })

            this.statusLoading = false
        },

        openSiteUrl,

        filterColumnData(columnData: { [index: string]: string }) {
            const columnOrder = this.columns.map(({ field }) => field)
            const exclude = ['__index', 'id', '__typename']

            return Object.keys(columnData)
                .filter((key) => !exclude.includes(key))
                .sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b))
                .reduce((obj: { [index: string]: string }, key) => {
                    obj[key] = columnData[key]
                    return obj
                }, {})
        },

        addColor(text: string): string {
            text = text.replace(
                /<([a-z]+)>(.*?)<\/[a-z]+>/g,
                (_, color, content) => `<span style="color:${color}">${content}</span>`,
            )
            text = sanitize(text, {
                allowedTags: ['span'],
                allowedAttributes: {
                    span: ['style'],
                },
            })
            return text
        },

        async showSiteNotes() {
            this.$q
                .dialog({
                    title: 'Site Notes',
                    prompt: {
                        model: this.siteNote?.note || '',
                        type: 'textarea',
                    },
                    cancel: true,
                    ok: 'Save',
                })
                .onOk(async (note: string) => {
                    await this.$apollo.mutate({
                        mutation: gql`
              mutation setSiteNote($clientId: String!, $siteId: String!, $note: String!) {
                setSiteNote(clientId: $clientId, siteId: $siteId, note: $note) {
                  id
                  note
                }
              }
            `,
                        variables: {
                            siteId: this.$route.params.siteId,
                            clientId: this.$route.params.clientId,
                            note,
                        },
                        update: (proxy, { data }) => {
                            proxy.writeQuery({
                                query: siteNoteQuery,
                                variables: {
                                    siteId: this.$route.params.siteId,
                                    clientId: this.$route.params.clientId,
                                },
                                data: { siteNote: data.setSiteNote },
                            })
                        },
                    })
                })
        },
    },
})
