/* eslint-disable no-template-curly-in-string */
import { states } from './states'
import { siteAndClient_client } from 'schema-types/siteAndClient'

const openSiteUrl = (
  url: string,
  path?: string | null,
  client?: siteAndClient_client,
  addressIndex?: number,
): void => {
  if (path && client && addressIndex !== undefined && client.addresses[addressIndex]) {
    path = path
      .replace('${firstName}', client.firstName)
      .replace('${lastName}', client.lastName)
      .replace('${streetAddress}', client.addresses[addressIndex].streetAddress!)
      .replace('${zipCode}', client.addresses[addressIndex].zipCode!)
      .replace('${state}', client.addresses[addressIndex].state!)
      .replace('${fullState}', states[client.addresses[addressIndex].state!])
      .replace('${city}', client.addresses[addressIndex].city ?? '')
  }
  if (!path) {
    path = ''
  }
  const urlRegex = /^https?:\/\//
  // Don't include base url if path is a url itself
  const urlToOpen = urlRegex.test(path) ? path : url + path
  window.open(urlToOpen, '_blank')
}

export { openSiteUrl }
