import { ClientType as ClientTypes } from './../../../schema-types/globalTypes'
import { formatForDisplay, localStoreFilters } from '@/utils'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'
import type { ApolloError } from '@apollo/client'
import { deepScanPositiveHits as deepScanPositiveHitsType } from 'schema-types/deepScanPositiveHits'
import { history_history } from 'schema-types/history'
import { sitesForPositiveHits_sites } from 'schema-types/sitesForPositiveHits'

const historyQuery = gql`
  query historyForForPositiveHits($clientId: String!, $siteId: String!) {
    history(clientId: $clientId, siteId: $siteId) {
      id
      createdAt
      from
      to
    }
  }
`
const sites = gql`
  query sitesForPositiveHits($onlyDeepScanEnabled: Boolean) {
    sites(onlyDeepScanEnabled: $onlyDeepScanEnabled) {
      name
      id
    }
  }
`
const deepScanPositiveHits = gql`
  query deepScanPositiveHits(
    $isClaimed: Boolean
    $isClaimedByMe: Boolean
    $siteIds: [String!]
    $orderBy: [OrderBy!]
    $searchBy: SearchBy
    $page: Int!
    $perPage: Int!
  ) {
    deepScanPositiveHits(
      isClaimed: $isClaimed
      isClaimedByMe: $isClaimedByMe
      siteIds: $siteIds
      orderBy: $orderBy
      searchBy: $searchBy
      page: $page
      perPage: $perPage
    ) {
      data {
        id
        client {
          id
          firstName
          lastName
          ClientType
        }
        site {
          id
          name
        }
        siteStatus
        lastCreatedDeepScanAt
        claimedAt
        claimedBy {
          id
          name
        }
      }
      totalCount
      totalPages
    }
  }
`

export default defineComponent({
  name: 'DeepScanHits',

  data() {
    const filters = localStoreFilters.getItem('dsh-filter') as
      | {
          claimed: string
          siteNameFilter: { id: string; name: string }[] | null
          sortBy: string
          descending: boolean
          rowsPerPage: number
        }
      | undefined

    return {
      deepScanPositiveHits: [] as {
        client: {
          id: string
          name: string
          type: string
        }
        site: {
          id: string
          name: string
        }
        scanDate: string
        daysSinceScan: string
        status: string
        claimedBy: {
          id: string
          name: string
        }
        claimedDate: string
        viewDetails: boolean
      }[],
      deepScanPositiveHitsError: false as boolean | ApolloError,
      sites: [] as sitesForPositiveHits_sites[],
      table: {
        filterInput: { field: 'clientName', value: '' },
        columns: [
          {
            name: 'clientName',
            label: 'Client',
            align: 'left',
            field: 'clientName',
            sortable: true,
          },
          {
            name: 'siteName',
            align: 'left',
            label: 'Site',
            field: 'siteName',
            sortable: true,
          },
          {
            name: 'scanDate',
            align: 'left',
            label: 'Scan date ',
            field: 'scanDate',
            sortable: true,
          },
          {
            name: 'daysSinceScan',
            align: 'left',
            label: 'Days Listed',
            field: 'daysSinceScan',
            sortable: true,
          },
          {
            name: 'status',
            align: 'left',
            label: 'Site Status',
            field: 'status',
            sortable: true,
          },
          {
            name: 'claimedBy',
            align: 'left',
            label: 'Claimed',
            field: 'claimedBy',
            sortable: true,
          },
          {
            name: 'claimedDate',
            align: 'left',
            label: 'Claimed date',
            field: 'claimedDate',
            sortable: true,
          },
          {
            name: 'viewDetails',
            align: 'left',
            label: 'View Details',
            field: 'viewDetails',
            headerStyle: 'width: 154px; text-align: center;',
            sortable: false,
          },
        ],
        claimed: filters?.claimed || ('un' as 'all' | 'my' | 'un'),
        siteNameFilter: filters?.siteNameFilter || (null as { id: string; name: string }[] | null),
        orderBy: [
          { field: 'clientName', direction: 'asc' },
          { field: 'lastCreatedDeepScanAt', direction: 'desc' },
        ],
        page: 1,
        totalPages: 1,
        pagination: {
          sortBy: filters?.sortBy || 'clientName',
          descending: filters?.descending || false,
          rowsPerPage: filters?.rowsPerPage !== undefined ? filters.rowsPerPage : 50,
        },
      },
      showFiltering: false,
      sitesOptions: [] as { id: string; name: string }[],
      appDisplayName: process.env.VUE_APP_DISPLAYNAME,
      history: [] as history_history[],
      selectedStatus: 'EXIST',
      claimSiteLoading: false,
    }
  },

  apollo: {
    deepScanPositiveHits: {
      fetchPolicy: 'no-cache',
      query: deepScanPositiveHits,
      variables() {
        return {
          isClaimed: this.table.claimed === 'all',
          isClaimedByMe: this.table.claimed === 'my',
          siteIds: this.table.siteNameFilter?.map((el) => el.id),
          orderBy: this.table.orderBy,
          searchBy: {
            field: this.table.filterInput.field,
            value: this.table.filterInput.value.trimStart(),
          },
          page: this.table.page,
          perPage: this.table.pagination.rowsPerPage || 1000 * 1000,
        }
      },
      result({ data }: { data: deepScanPositiveHitsType }) {
        this.deepScanPositiveHitsError = false

        if (data?.deepScanPositiveHits?.data?.length) {
          this.deepScanPositiveHits = data.deepScanPositiveHits.data.map((el) => {
            const daysAgo =
              Math.ceil(
                Math.abs(
                  Number(new Date(Date.now())) - Number(new Date(el!.lastCreatedDeepScanAt)),
                ) /
                  (1000 * 60 * 60 * 24),
              ) - 1

            this.table.totalPages = data.deepScanPositiveHits?.totalPages || 1

            return {
              client: {
                id: el!.client.id,
                name: `${el!.client.firstName} ${el!.client.lastName}`,
                type: el!.client!.ClientType!,
              },
              site: {
                id: el!.site.id,
                name: el!.site.name,
              },
              scanDate: this.formatForDisplay(el!.lastCreatedDeepScanAt, 'updatedAt')!,
              daysSinceScan: `${daysAgo}`,
              status: el!.siteStatus,
              claimedBy: {
                id: el!.claimedBy?.id || '-',
                name: el!.claimedBy?.name || '-',
              },
              claimedDate: this.formatForDisplay(el!.claimedAt, 'updatedAt') || '-',
              viewDetails: true,
            }
          })
        } else {
          this.deepScanPositiveHits = []
        }
      },
      error(error) {
        this.deepScanPositiveHitsError = error
      },
    },

    sites: {
      query: sites,
      variables() {
        return {
          onlyDeepScanEnabled: true,
        }
      },
      result({ data }) {
        if (data) {
          this.deepScanPositiveHitsError = false
          this.sites = data.sites
          this.sitesOptions = data.sites?.map((el: any) => ({ id: el.id, name: el.name }))
        }
      },
      error(err) {
        this.deepScanPositiveHitsError = err
      },
    },

    history: {
      query: historyQuery,
      variables() {
        return {
          clientId: this.$route.params.clientId,
          siteId: this.$route.params.siteId,
        }
      },
      skip() {
        return !this.$route.params.siteId || !this.$route.params.clientId
      },
      result({ data }) {
        if (data) {
          this.deepScanPositiveHitsError = false

          const historyData = data.history
          if (historyData.length > 0) {
            const latestStatus = historyData[historyData.length - 1].to
            if (latestStatus) {
              this.selectedStatus = latestStatus
            }
          }
        }
      },
      error(err) {
        this.deepScanPositiveHitsError = err
      },
    },
  },

  methods: {
    saveFilters() {
      localStoreFilters.setItem('dsh-filter', {
        claimed: this.table.claimed,
        siteNameFilter: this.table.siteNameFilter,
        sortBy: this.table.pagination.sortBy,
        descending: this.table.pagination.descending,
        rowsPerPage: this.table.pagination.rowsPerPage,
      })
    },

    async claimSite(clientId: string, siteId: string) {
      this.claimSiteLoading = true

      await this.$apollo
        .mutate({
          mutation: gql`
            mutation claimSitePositiveHits(
              $clientId: String!
              $siteId: String!
              $action: CheckOutSiteAction!
            ) {
              checkOutSite(payload: { clientId: $clientId, siteId: $siteId, action: $action }) {
                id
              }
            }
          `,
          variables: { siteId, clientId, action: 'CLAIM' },
        })
        .then(() => {
          this.$apollo.queries.deepScanPositiveHits.refetch().finally()

          this.$q.dialog({
            title: 'Claimed',
            message: 'You claimed site',
          })
        })
        .catch((err) => {
          this.$q.dialog({
            title: 'Error claim site',
            message: err.message.replace('GraphQL error:', '').trim(),
          })
        })

      this.claimSiteLoading = false
    },

    formatForDisplay(data: any, name: string) {
      if (name === 'status' && typeof data === 'string') {
        return data.toLowerCase().replace(/^./, (t) => t.toUpperCase())
      }
      return formatForDisplay(data, name)
    },

    getColStyles(ClientType: string) {
      const baseStyles = {
        'padding-top': '4px',
        'padding-bottom': '4px',
        'padding-right': '8px',
        'padding-left': '8px',
        'border-radius': '4px',
      }

      switch (ClientType) {
        case ClientTypes.NONUS:
          return {
            background: 'rgb(37 99 235)',
            color: 'white',
            ...baseStyles,
          }

        case ClientTypes.SPECIAL:
          return {
            background: '#fdbfbf',
            color: '#a20808',
            ...baseStyles,
          }

        case ClientTypes.EMPLOYEE:
          return {
            background: '#c0f5bb',
            color: '#0e5a19',
            ...baseStyles,
          }

        case ClientTypes.TEST:
          return {
            background: 'rgb(254 240 138)',
            color: 'black',
            ...baseStyles,
          }

        case ClientTypes.GROUP:
          return {
            background: '#ffd08a',
            color: '#845000',
            ...baseStyles,
          }
        default:
          break
      }
    },

    filterFn(val: string, update: any) {
      if (val === '') {
        update(() => {
          this.sitesOptions = this.sites?.map((el) => ({ id: el.id, name: el.name }))
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.sitesOptions = this.sites
          ?.map((el) => ({ id: el.id, name: el.name }))
          ?.filter((e) => e.name.toLowerCase().includes(needle.trimStart()))
      })
    },
  },

  mounted() {
    this.$apollo.queries.deepScanPositiveHits.refetch().finally()
    this.$apollo.queries.sites.refetch()
    this.$apollo.queries.history.refetch()
  },

  watch: {
    'table.claimed': {
      handler() {
        this.saveFilters()
      },
    },
    'table.siteNameFilter': {
      handler() {
        this.saveFilters()
      },
    },
    'table.pagination': {
      handler(prev, next) {
        this.saveFilters()

        if (next.sortBy || prev.sortBy) {
          this.table.orderBy = [
            {
              field: next.sortBy || prev.sortBy,
              direction: (next.sortBy ? !next.descending : !prev.descending) ? 'asc' : 'desc',
            },
            { field: 'lastCreatedDeepScanAt', direction: 'desc' },
          ]
        }
      },
    },
  },
})
