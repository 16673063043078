import { User_authLevel } from '../../schema-types/globalTypes'
import type { DollarApollo } from '@vue/apollo-option/types/vue-apollo'
import { currentUserQuery } from '@/queries'

const authLevels: User_authLevel[] = [
  User_authLevel.USER,
  User_authLevel.ADMIN,
  User_authLevel.OWNER,
]

export { User_authLevel, authLevels }

export const hasAuthLevel = async (
  apollo: DollarApollo<unknown>,
  minAuthLevel: User_authLevel,
): Promise<boolean> => {
  try {
    const { data }: { data: { currentUser: { authLevel: User_authLevel } } } = await apollo.query({
      query: currentUserQuery,
    })
    if (
      !data.currentUser ||
      authLevels.indexOf(data.currentUser.authLevel) < authLevels.indexOf(minAuthLevel)
    ) {
      return false
    } else {
      return true
    }
  } catch (error) {
    return false
  }
}
