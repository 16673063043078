import { defineComponent } from 'vue'
import gql from 'graphql-tag'
import { passwordRules } from '@/utils'

export default defineComponent({
  name: 'ChangePassword',
  data() {
    return {
      passwordInput: '',
      changingPassword: false,
      passwordRules,
    }
  },
  methods: {
    async sendEmail() {
      // TODO: send email
    },
  },
})
