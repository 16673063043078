import { sortWithDate } from '@/utils'
import { ApolloError } from '@apollo/client'
import gql from 'graphql-tag'
import { defineComponent } from 'vue'

const queueLogQuery = gql`
  query queueLog {
    queueLog {
      key
      time
      user {
        id
        name
      }
      client {
        id
        firstName
        lastName
        email
      }
      event
    }
  }
`

const UNDO_QUEUE_EVENT_MUTATION = gql`
  mutation UndoQueueEvent($key: String!) {
    undoQueueEvent(key: $key)
  }
`

export default defineComponent({
    name: 'QueueLogPage',

    data() {
        return {
            isUser: false,
            isAdmin: false,
            logs: [] as any[],
            queryError: null as ApolloError | null,
            loading: false,
            table: {
                columns: [
                    { name: 'time', required: true, label: 'Time', align: 'left' as const, field: 'time', sortable: true },
                    { name: 'user', required: true, label: 'User', align: 'left' as const, field: 'user', sortable: true },
                    { name: 'client', required: true, label: 'Client', align: 'left' as const, field: 'client', sortable: true },
                    { name: 'event', required: true, label: 'Event', align: 'left' as const, field: 'event', sortable: true },
                    { name: 'undo', required: false, label: '', align: 'center' as const, field: 'undo', sortable: false },
                ],
                sortBy: 'time',
                descending: true,
                pagination: {
                    sortBy: 'time',
                    page: 1,
                    rowsPerPage: 50,
                },
            },
        }
    },

    mounted() {
        this.fetchLogs();
    },

    methods: {
        sortWithDate,

        async undoEvent(key: string) {
            try {
                this.$q.notify('Please wait.')
                await this.$apollo.mutate({
                    mutation: UNDO_QUEUE_EVENT_MUTATION,
                    variables: { key },
                })
                this.$q.notify('Sent Client back to User')
                await this.fetchLogs()
            } catch (error) {
                console.error('Error undoing event:', error)
            }
        },

        formatDate(timestamp: number): string {
            return new Date(timestamp).getFullYear().toString();
        },

        async fetchLogs() {
            this.$apollo.query({
                query: queueLogQuery,
            }).then(({ data }) => {
                if (data) {
                    this.logs = data.queueLog.map((log: any) => ({
                        key: log.key,
                        time: new Date(log.time),
                        user: log.user.name,
                        client: log.client.email,
                        event: log.event,
                    }))
                    this.queryError = null
                } else {
                    this.logs = []
                }
                this.loading = false
            })
        }
    },
})
