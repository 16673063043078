import { gql } from 'graphql-tag'
import { defineComponent } from 'vue'
import type { testCse_testCse_results } from 'schema-types/testCse'

export default defineComponent({
  data() {
    return {
      clientEmail: '',
      query: '',
      cseOptions: {
        threshold: 0.5,
        name: true,
        zipCode: true,
        streetAddress: false,
        state: false,
        city: false,
      },
      results: [] as testCse_testCse_results[],
      filterResults: '',
    }
  },
  methods: {
    search() {
      this.$apollo
        .query({
          query: gql`
            query testCse($clientEmail: String!, $cseOptions: cseOptionsInput!, $query: String) {
              testCse(clientEmail: $clientEmail, cseOptions: $cseOptions, query: $query) {
                results {
                  title
                  link
                  snippet
                  included
                  fullData
                }
              }
            }
          `,
          variables: {
            clientEmail: this.clientEmail,
            cseOptions: this.cseOptions,
            query: this.query,
          },
        })
        .then(({ data }) => {
          this.results = data.testCse.results
        })
        .catch((err) => {
          this.$q.dialog({
            title: 'Error',
            message: err.message,
          })
        })
    },
    showFullData(data: string) {
      this.$q.dialog({
        title: 'Full data',
        message: data,
        style: 'white-space: pre-wrap',
      })
    },
  },
})
