import { defineComponent } from 'vue'
import gql from 'graphql-tag'
import semver from 'semver'
import type { appVersion } from 'schema-types/appVersion'

export default defineComponent({
  name: 'Loading',
  data() {
    return {
      logoPath: require(`@/assets/logo-${process.env.VUE_APP_NAME}.png`),
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.$apollo
        .query<appVersion>({
          query: gql`
            query appVersion {
              appVersion {
                latest
                minimum
              }
            }
          `,
        })
        .then(({ data }) => {
          if (semver.gte(__appVersion, data.appVersion.latest)) {
            if (this.$route.query.toChangePassword === 'true') {
              this.$router.push({
                path: '/changePassword',
                query: {
                  token: this.$route.query.token,
                },
              })
            } else {
              this.$router.push({
                path: this.$route.query.toSignUp === 'true' ? '/signup' : '/login',
                query: {
                  moonclerkCustomerId: this.$route.query.moonclerkCustomerId,
                },
              })
            }
          } else if (semver.gte(__appVersion, data.appVersion.minimum)) {
            this.$q.notify({
              message: 'An update is available!',
              color: 'warning',
              textColor: 'black',
              icon: 'warning',
              actions: [
                {
                  label: 'Reload To Update',
                  color: 'blue',
                  handler: () => window.location.reload(),
                },
              ],
              timeout: 0,
            })
            this.$router.push(this.$route.query.toSignUp === 'true' ? '/signup' : '/login')
          } else {
            this.$q
              .dialog({
                title: 'Update Required',
                message: 'It is required that you update your app before continuing.',
                persistent: true,
                ok: {
                  label: 'Reload To Update',
                },
              })
              .onOk(() => window.location.reload())
          }
        })
        .catch((err) => {
          this.$q
            .dialog({
              title: 'Failed to Connect to Server',
              message: err.message,
              persistent: true,
              ok: {
                label: 'Retry',
              },
            })
            .onOk(() => {
              this.load()
            })
        })
    },
  },
})
