<template>
  <div
    class="min-h-screen flex-grow flex flex-col place-content-center place-items-center p-4 md:p-8"
  >
    <q-dialog persistent v-model="enableTwoFactorDialog">
      <two-factor-enable prevent-cancel @completion="finishLogin"></two-factor-enable>
    </q-dialog>
    <div
      class="p-4 py-6 md:py-12 md:bg-transparent shadow-4 rounded-sm max-w-3xl w-full grid grid-cols-1 md:grid-cols-2 place-items-center color-slash"
    >
      <div>
        <img width="200" height="200" :src="logoPath" :alt="`${appDisplayName} Logo`" />
      </div>

      <q-form class="mt-10 md:mt-0" @submit="login">
        <div class="flex flex-col gap-y-4">
          <op-input
            light
            class="row col-xs-12"
            v-model="emailInput"
            label="Email"
            :rules="[required]"
          ></op-input>

          <op-input
            light
            class="row col-xs-12"
            v-model="passwordInput"
            label="Password"
            type="password"
            :rules="[required]"
          ></op-input>

          <op-input
            light
            class="row col-xs-12"
            v-model="totpInput"
            label="2FA code (if enabled)"
            type="number"
          ></op-input>

          <router-link class="text-blue-800 underline" to="/requestPasswordResetLink">
            Reset Password
          </router-link>

          <q-btn
            unelevated
            rounded
            :loading="loggingIn"
            color="primary"
            type="submit"
            label="Login"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>

<script src="./Login.ts"></script>

<style scoped>
@media (max-width: 767px) {
  .color-slash {
    background-image: -webkit-linear-gradient(290deg, #ffffff 25%, var(--q-secondary) 25%);
  }
}
</style>
