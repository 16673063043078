<template>
  <div class="row justify-around q-mt-lg">
    <q-card class="col q-gutter-sm q-pa-md" :flat="$q.screen.xs" style="max-width: 400px">
      <div class="row justify-center">
        <q-icon size="80px" color="primary" name="account_circle" />
      </div>
      <div class="row justify-center text-h5">Request Password Reset Link</div>
      <q-form @submit="requestLink">
        <q-input
          class="row col-xs-12"
          v-model="emailInput"
          label="Email"
          :rules="[required]"
        ></q-input>
        <div class="row justify-center q-pt-sm">
          <q-btn :loading="requestingLink" color="primary" type="submit"
            >Send Link to Reset Password</q-btn
          >
        </div>
      </q-form>
    </q-card>
  </div>
</template>

<script src="./RequestPasswordResetLink.ts"></script>

<style></style>
